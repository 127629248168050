<template>
  <v-container class="full-height" id="portfolio">
    <h2 class="text-h2 mb-10">Portfolio</h2>
    <v-carousel show-arrows="hover" cycle hide-delimiters>
      <v-carousel-item
        src="https://images.unsplash.com/photo-1588854337221-4cf9fa96059c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
        cover
      ></v-carousel-item>

      <v-carousel-item
        src="https://images.unsplash.com/photo-1484154218962-a197022b5858?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2074&q=80"
        cover
      ></v-carousel-item>

      <v-carousel-item
        src="https://plus.unsplash.com/premium_photo-1680382578857-c331ead9ed51?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"
        cover
      ></v-carousel-item>
    </v-carousel>
  </v-container>
</template>
<style>
#portfolio {
  max-width: 1000px;
}
</style>
